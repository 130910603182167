import { jsx as I } from "@emotion/react/jsx-runtime";
import { useRef as a, useEffect as h, useCallback as l, useMemo as R, useContext as _ } from "react";
const y = (s, t) => {
  var c;
  let e = s.split("?");
  const r = (c = e.shift()) != null ? c : "";
  e = e.join("?").split("#");
  const n = new URLSearchParams(e.shift());
  return n.append("_rdt_sid", t.sectionId), typeof t.sectionItemIdx == "number" && n.append("_rdt_idx", `${t.sectionItemIdx}`), typeof t.sectionArg == "string" && n.append("_rdt_arg", t.sectionArg), typeof t.sourceId == "string" && n.append("_s_id", t.sourceId), e.unshift(`${r}?${n.toString()}`), e.join("#");
}, U = (s, t) => ({ children: e }) => {
  const r = a(/* @__PURE__ */ new Map()), n = a(null);
  h(() => {
    const o = new IntersectionObserver((i, f) => {
      i.forEach((g) => {
        var v;
        const { target: b, isIntersecting: p } = g;
        p && ((v = r.current.get(b)) == null || v(), r.current.delete(b), f.unobserve(b));
      });
    }, t);
    return r.current.forEach((i, f) => {
      o.observe(f);
    }), n.current = o, () => o.disconnect();
  }, []);
  const c = l((o, i) => {
    var f;
    r.current.set(o, i), (f = n.current) == null || f.observe(o);
  }, []), u = l((o) => {
    var i;
    r.current.has(o) && (r.current.delete(o), (i = n.current) == null || i.unobserve(o));
  }, []), d = R(
    () => ({
      observe: c,
      unobserve: u
    }),
    [c, u]
  );
  return /* @__PURE__ */ I(s.Provider, { value: d, children: e });
}, k = (s) => (t) => {
  const e = _(s), r = a(null), n = a(!1), c = a(t);
  return c.current = t, l(
    (u) => {
      if (!n.current) {
        if (u === null) {
          r.current !== null && (e == null || e.unobserve(r.current), r.current = null);
          return;
        }
        e == null || e.observe(u, () => {
          n.current = !0, c.current();
        }), r.current = u;
      }
    },
    [e]
  );
}, w = (s, t) => s.reduce((e, r, n, c) => (e.push(r), n === c.length - 1 || e.push(t), e), []), x = (s) => typeof s != "number" || !Number.isFinite(s) ? "" : s.toLocaleString("ko-KR");
export {
  k as a,
  y as b,
  U as c,
  x as f,
  w as j
};
